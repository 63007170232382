




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter } from 'vuex-class';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonShareInformation extends VueBaseActionButtonWidget {
  @Prop({ required: false })
  protected readonly filterSlotActionResult!: { type: string; limit: number; code: string }[];

  private isInformationFeatureEnabled = false;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private get text(): string {
    return `${this.$t('actions.share-contact')}`;
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.SHARE_CONTACT)
        && this.entityType === this.EntityTypeEnum.EXHIBITOR
        && this.isInformationFeatureEnabled;
  }

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    return salesServiceFeature && salesServiceFeature.enabled;
  }

  created(): void {
    if (this.isNewLimitCalculationFeatureEnabled) {
      if (this.filterSlotActionResult && this.filterSlotActionResult[0]) {
        this.isInformationFeatureEnabled = this.filterSlotActionResult[0].limit === -1
            || this.filterSlotActionResult[0].limit > 0;
      } else {
        this.isInformationFeatureEnabled = false;
      }
    } else {
      this.isInformationFeatureEnabled = true;
    }
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private toggleShareInformationModal(): void {
    if (this.authUser) {
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: [{
          uid: this.authUser.uid,
          fullName: this.entityName,
        }],
        groupId: this.entityUid,
        actionType: MessageMenuItem.SHARE_CONTACT,
      });
    } else {
      this.$bvModal.show('sign-in-action-modal');
    }
  }
}
